<template>
    <div class="SavingsCalculator" :class="{ 'is-loading': isFetchingData }">
        <!-- Updated headline -->
        <Headline title="Rücklagenrechner">
            <div class="SavingsCalculator-Summary">
                <p style="color:red">Unerledigte Fahrer: {{ missedEmployees.length }}</p>
                <p>Abgerechnete Fahrer: {{ completedEmployees.length }}</p>
                <p>Offene Fahrer: {{ unfinishedEmployees.length }}</p>
            </div>
        </Headline>

        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    :items="employeesOptions"
                    :selected="selectedEmployee"
                    placeholder="Fahrer Wählen"
                    @onItemSelect="handleEmployeeSelect"
                />
                <Datepicker
                    @onChange="handleDateChange"
                    :startDate="selectedFrom"
                    :endDate="selectedTo"
                />
                <Button @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
            </div>
            <div>
                <Button @onClick="showCsvImportModal" variant="basic">
                    CSV Importieren
                </Button>
            </div>
        </Toolbar>

        <!-- Displaying tables if there is any data -->
        <div class="SavingsCalculator-Tables" v-if="!isFetchingData">
            <div>
                <h5>Unerledigte Fahrer</h5>
                <EmployeeTable
                    v-if="missedEmployees.length"
                    title="Unerledigte Fahrer"
                    hasBadge
                    :employees="missedEmployees"
                    @onPayClick="navigateToEmployee"
                    @onRowClick="props => handleRowClick({ ...props, type: 'employee' })"
                />
            </div>
            <div>
                <h5>Offene Fahrer</h5>
                <EmployeeTable
                    v-if="unfinishedEmployees.length"
                    :employees="unfinishedEmployees"
                    title="Offene Fahrer"
                    hasBadge
                    @onPayClick="navigateToEmployee"
                    @onRowClick="props => handleRowClick({ ...props, type: 'employee' })"
                />
            </div>
            <div>
                <h5>Abgerechnete Fahrer</h5>
                <EmployeeTable
                    class="SavingsCalculator-Table-Completed"
                    v-if="completedEmployees.length"
                    :employees="completedEmployees"
                    title="Abgerechnete Fahrer"
                    hasBadge
                    @onPayClick="navigateToEmployee"
                    @onRowClick="props => handleRowClick({ ...props, type: 'employee' })"
                />
            </div>
        </div>

        <!-- Empty state when there's no data -->
        <EmptyState v-else />
        <Modal
            :title="'Importieren Sie Ihre Daten'"
            :show="csvImportModal.active"
            :action="{ text: 'Daten Importieren', color: 'dark', callback: importCsv }"
            :cancel="{ text: 'Abbrechen' }"
            @onModalClose="handleCsvImportModalClose"
        >
            <p>
                Laden Sie Ihre CSV von
                <a
                    href="https://portal.free-now.com/earnings"
                    target="_blank"
                    rel="noopener noreferrer"
                    >FreeNow</a
                >, Uber,
                <a
                    href="https://fleets.bolt.eu/v2/reports"
                    target="_blank"
                    rel="noopener noreferrer"
                    >Bolt</a
                >
                und
                <a href="https://fleet.bliq.app/earnings" target="_blank" rel="noopener noreferrer"
                    >Bliq</a
                >. hier hoch um sie in taxiFusion einzupflegen.
            </p>
            <br />

            <!-- Drag and Drop or File Input -->
            <div class="FileDropArea" @dragover.prevent @drop.prevent="handleCsvDrop">
                <p>
                    Drag and Drop here or
                    <a href="#" @click.prevent="triggerFileInput">Browse files</a>
                </p>
                <input type="file" ref="fileInput" @change="handleCsvChange" accept=".csv" hidden />
            </div>
            <div v-if="csvImportModal.file">
                <strong>Datei:</strong> {{ csvImportModal.file.name }}
            </div>
        </Modal>
    </div>
</template>

<script>
import Modal from '@/components/widgets/Modal.vue';
import Input from '@/components/widgets/Input.vue';

import { startOfDay, endOfDay, startOfMonth, addDays } from 'date-fns';
import { faker } from '@faker-js/faker';
import Datepicker from '@/components/widgets/Datepicker';
// import Download from '@/components/widgets/Download';
import Button from '@/components/widgets/Button';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import EmptyState from '@/components/EmptyState.vue';
import EmployeeTable from './components/EmployeeTable.vue';

export default {
    name: 'SavingsCalculator',
    components: {
        Datepicker,
        // Download,
        Button,
        Dropdown,
        Headline,
        Toolbar,
        EmptyState,
        EmployeeTable,
        Modal,
    },
    data: () => {
        return {
            csvImportModal: {
                active: false,
                file: null,
            },
            selectedEmployee: null,
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfDay(new Date()),
            isFetchingData: false,
            employees: [],
            missedEmployees: [],
            completedEmployees: [],
            unfinishedEmployees: [],
        };
    },
    computed: {
        isMobile() {
            return window.innerWidth < 500;
        },
        employeesOptions() {
            return this.employees.map(e => ({
                ...e,
                label: `${e.firstName} ${e.lastName}`,
            }));
        },
    },
    methods: {
        handleCsvImportModalClose() {
            this.csvImportModal.active = false;
            this.csvImportModal.file = null;
        },
        handleCategorySelect({ item }) {
            this.selectedCategory = item;
            if (item.id !== 'custom') {
                this.expenseModal.category = item.value;
            } else {
                this.expenseModal.category = '';
            }
        },
        handleAmountChange({ value }) {
            this.expenseModal.amount = value;
        },
        handleCsvChange(event) {
            const file = event.target.files[0];
            if (file && file.type === 'text/csv') {
                this.csvImportModal.file = file;
            } else {
                console.warn('Invalid file type. Only .csv files are supported.');
            }
        },
        handleCsvDrop(event) {
            event.preventDefault();
            const file = event.dataTransfer.files[0];
            if (file && (file.type === 'text/csv' || file.name.endsWith('.csv'))) {
                this.csvImportModal.file = file;
            } else {
                console.warn('Invalid file type. Only .csv files are supported.');
            }
        },
        importCsv() {
            if (this.csvImportModal.file) {
                console.log('Importing CSV:', this.csvImportModal.file.name);
                // Add your logic for handling the CSV file import.
                this.handleCsvImportModalClose();
            } else {
                console.warn('No CSV file selected for import.');
            }
        },
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        generateEmployees(count = 5) {
            const generatedEmployees = Array.from({ length: count }).map(() => {
                const isCompleted = faker.datatype.boolean();
                const completedDate = isCompleted
                    ? faker.date.between({ from: this.selectedFrom, to: this.selectedTo })
                    : null;

                return {
                    color: isCompleted ? 'var(--color-green)' : 'var(--color-yellow-dark)',
                    id: faker.string.uuid(),
                    value: faker.person.firstName(),
                    income: faker.finance.amount({ min: 3000, max: 5000, fixed: 2 }),
                    expense: faker.finance.amount({ min: 200, max: 1000, fixed: 2 }),
                    profit: faker.finance.amount({ min: 2000, max: 4000, fixed: 2 }),
                    atDriver: faker.finance.amount({ min: 400, max: 800, fixed: 2 }),
                    atCompany: faker.finance.amount({ min: 2500, max: 3500, fixed: 2 }),
                    driverShare: faker.finance.amount({ min: 1000, max: 2000, fixed: 2 }),
                    payoutAmount: faker.finance.amount({ min: 100, max: 500, fixed: 2 }),
                    openAmount: !isCompleted
                        ? faker.finance.amount({ min: 200, max: 1000, fixed: 2 })
                        : 0,
                    isCompleted: isCompleted,
                    completedAt: completedDate,
                    isUnfinishedPast: !isCompleted && faker.datatype.boolean(), // Make some unfinished and add it to missed employee criteria
                };
            });

            // Simulate Missed Employees from a past period
            const missedEmployees = Array.from({ length: 5 }).map(() => {
                return {
                    id: faker.string.uuid(),
                    value: faker.person.firstName(),
                    income: faker.finance.amount({ min: 3000, max: 5000, fixed: 2 }),
                    expense: faker.finance.amount({ min: 200, max: 1000, fixed: 2 }),
                    profit: faker.finance.amount({ min: 2000, max: 4000, fixed: 2 }),
                    atDriver: faker.finance.amount({ min: 400, max: 800, fixed: 2 }),
                    atCompany: faker.finance.amount({ min: 2500, max: 3500, fixed: 2 }),
                    driverShare: faker.finance.amount({ min: 1000, max: 2000, fixed: 2 }),
                    payoutAmount: faker.finance.amount({ min: 100, max: 500, fixed: 2 }),
                    openAmount: faker.finance.amount({ min: 200, max: 1000, fixed: 2 }),
                    isCompleted: false, // Ensure they are unfinished
                    completedAt: null,
                    isUnfinishedPast: true, // Mark as a missed employee
                };
            });

            return [...generatedEmployees];
        },
        async handleQuery() {
            this.isFetchingData = true;

            try {
                // Generate or fetch employee data
                const employees = this.generateEmployees(30); // Simulated data. Replace with your data fetching logic

                // Filter based on selected employee
                const filteredEmployees = this.selectedEmployee
                    ? employees.filter(emp => emp.value === this.selectedEmployee)
                    : employees;

                // Filter based on selected date range
                const dateFilteredEmployees = filteredEmployees.filter(emp => {
                    if (emp.completedAt) {
                        const completedDate = new Date(emp.completedAt);
                        return (
                            completedDate >= startOfDay(this.selectedFrom) &&
                            completedDate <= endOfDay(this.selectedTo)
                        );
                    }
                    return true; // Keep unfinished employees, regardless of date range
                });

                // Classify the remaining employees into completed and unfinished
                this.completedEmployees = dateFilteredEmployees.filter(emp => emp.isCompleted);
                this.unfinishedEmployees = dateFilteredEmployees.filter(emp => !emp.isCompleted);
                this.missedEmployees = employees
                    .filter(emp => emp.isUnfinishedPast)
                    .map(e => ({ ...e, color: 'red' }));
            } catch (error) {
                console.error(error);
                this.$toasted.show('An error occurred.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
        handleEmployeeSelect({ item }) {
            this.selectedEmployee = item;
        },
        showCsvImportModal() {
            this.csvImportModal.active = true;
        },
        handleDownload() {
            console.log('Download CSV clicked');
        },
        handleDateChange(date) {
            console.log(date);
            this.selectedFrom = date.from;
            this.selectedTo = date.to;
        },
        navigateToEmployee({ id }) {
            this.$router.push({
                name: 'employee-revenue',
                params: { id, employees: this.unfinishedEmployees },
            });
        },
    },
    async mounted() {
        await this.handleQuery();
        this.isFetchingData = false;
        this.$emit('onFinishLoading');
    },
};
</script>

<style lang="scss">
.SavingsCalculator {
    @extend %contentWrapper;
    @extend %page;

    .SavingsCalculator-Summary {
        margin-bottom: 25px;
        margin-top: 15px;

        p {
            font-size: 14px;
        }
    }

    .Toolbar {
        margin-bottom: 25px;
    }
    .SavingsCalculator-Tables {
        display: flex;
        gap: 10px;

        > * {
            flex: 1;
        }
        .SavingsCalculator-Table-Completed {
            .Row {
            }
        }
        // flex-direction: column;
    }

    /* CSV Modal Drag-and-drop styles */

    a {
        text-decoration: underline;
    }
    .FileDropArea {
        border: 2px dotted rgba(0, 0, 0, 0.3);
        padding: 40px 20px;
        text-align: center;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        transition: background-color 0.3s, border-color 0.3s;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
            border-color: rgba(0, 0, 0, 0.6);
        }

        p {
            margin: 0;
            padding: 0;
        }

        a {
            color: #007bff;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
</style>
