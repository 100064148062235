var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "SavingsCalculator",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Headline', {
    attrs: {
      "title": "Rücklagenrechner"
    }
  }, [_c('div', {
    staticClass: "SavingsCalculator-Summary"
  }, [_c('p', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("Unerledigte Fahrer: " + _vm._s(_vm.missedEmployees.length))]), _c('p', [_vm._v("Abgerechnete Fahrer: " + _vm._s(_vm.completedEmployees.length))]), _c('p', [_vm._v("Offene Fahrer: " + _vm._s(_vm.unfinishedEmployees.length))])])]), _c('Toolbar', [_c('div', [_c('Dropdown', {
    attrs: {
      "hasSearch": "",
      "items": _vm.employeesOptions,
      "selected": _vm.selectedEmployee,
      "placeholder": "Fahrer Wählen"
    },
    on: {
      "onItemSelect": _vm.handleEmployeeSelect
    }
  }), _c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "endDate": _vm.selectedTo
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Button', {
    attrs: {
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")])], 1), _c('div', [_c('Button', {
    attrs: {
      "variant": "basic"
    },
    on: {
      "onClick": _vm.showCsvImportModal
    }
  }, [_vm._v(" CSV Importieren ")])], 1)]), !_vm.isFetchingData ? _c('div', {
    staticClass: "SavingsCalculator-Tables"
  }, [_c('div', [_c('h5', [_vm._v("Unerledigte Fahrer")]), _vm.missedEmployees.length ? _c('EmployeeTable', {
    attrs: {
      "title": "Unerledigte Fahrer",
      "hasBadge": "",
      "employees": _vm.missedEmployees
    },
    on: {
      "onPayClick": _vm.navigateToEmployee,
      "onRowClick": function onRowClick(props) {
        return _vm.handleRowClick(Object.assign({}, props, {
          type: 'employee'
        }));
      }
    }
  }) : _vm._e()], 1), _c('div', [_c('h5', [_vm._v("Offene Fahrer")]), _vm.unfinishedEmployees.length ? _c('EmployeeTable', {
    attrs: {
      "employees": _vm.unfinishedEmployees,
      "title": "Offene Fahrer",
      "hasBadge": ""
    },
    on: {
      "onPayClick": _vm.navigateToEmployee,
      "onRowClick": function onRowClick(props) {
        return _vm.handleRowClick(Object.assign({}, props, {
          type: 'employee'
        }));
      }
    }
  }) : _vm._e()], 1), _c('div', [_c('h5', [_vm._v("Abgerechnete Fahrer")]), _vm.completedEmployees.length ? _c('EmployeeTable', {
    staticClass: "SavingsCalculator-Table-Completed",
    attrs: {
      "employees": _vm.completedEmployees,
      "title": "Abgerechnete Fahrer",
      "hasBadge": ""
    },
    on: {
      "onPayClick": _vm.navigateToEmployee,
      "onRowClick": function onRowClick(props) {
        return _vm.handleRowClick(Object.assign({}, props, {
          type: 'employee'
        }));
      }
    }
  }) : _vm._e()], 1)]) : _c('EmptyState'), _c('Modal', {
    attrs: {
      "title": 'Importieren Sie Ihre Daten',
      "show": _vm.csvImportModal.active,
      "action": {
        text: 'Daten Importieren',
        color: 'dark',
        callback: _vm.importCsv
      },
      "cancel": {
        text: 'Abbrechen'
      }
    },
    on: {
      "onModalClose": _vm.handleCsvImportModalClose
    }
  }, [_c('p', [_vm._v(" Laden Sie Ihre CSV von "), _c('a', {
    attrs: {
      "href": "https://portal.free-now.com/earnings",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v("FreeNow")]), _vm._v(", Uber, "), _c('a', {
    attrs: {
      "href": "https://fleets.bolt.eu/v2/reports",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v("Bolt")]), _vm._v(" und "), _c('a', {
    attrs: {
      "href": "https://fleet.bliq.app/earnings",
      "target": "_blank",
      "rel": "noopener noreferrer"
    }
  }, [_vm._v("Bliq")]), _vm._v(". hier hoch um sie in taxiFusion einzupflegen. ")]), _c('br'), _c('div', {
    staticClass: "FileDropArea",
    on: {
      "dragover": function dragover($event) {
        $event.preventDefault();
      },
      "drop": function drop($event) {
        $event.preventDefault();
        return _vm.handleCsvDrop.apply(null, arguments);
      }
    }
  }, [_c('p', [_vm._v(" Drag and Drop here or "), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.triggerFileInput.apply(null, arguments);
      }
    }
  }, [_vm._v("Browse files")])]), _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "accept": ".csv",
      "hidden": ""
    },
    on: {
      "change": _vm.handleCsvChange
    }
  })]), _vm.csvImportModal.file ? _c('div', [_c('strong', [_vm._v("Datei:")]), _vm._v(" " + _vm._s(_vm.csvImportModal.file.name) + " ")]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }