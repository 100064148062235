var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "EmployeesTable Row-Wrap",
    class: [{
      'is-empty': _vm.mEmployees.length === 0
    }]
  }, [_vm.mEmployees.length > 0 ? _c('Row', {
    key: -1,
    staticClass: "Row-Header",
    attrs: {
      "parentHover": false,
      "items": {
        row: _vm.header
      },
      "index": -1
    },
    on: {
      "onClickColumn": _vm.handleHeaderClick
    }
  }) : _vm._e(), _vm._l(_vm.mEmployees, function (employee, i) {
    return _c('Row', {
      key: i,
      attrs: {
        "items": employee,
        "index": i,
        "hasBadge": _vm.hasBadge
      },
      on: {
        "onHover": function onHover($event) {
          _vm.activeRow = i;
        },
        "onLeave": function onLeave($event) {
          _vm.activeRow = -1;
        },
        "onClick": function onClick() {
          return _vm.$emit('onClick', employee);
        }
      }
    });
  }), _vm.mEmployees.length === 0 ? _c('EmptyState') : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }